.Contact{
  display: flex;
  width: calc(100%);
  height: calc(100%);

  perspective: 1000px;
}
.shopMarker{
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 1px;
  background: #111;
  position: relative;
  transform: translate(-50%, -50%);
}
.shopMarkerLogo{
  width: calc(100%);
  height: calc(100%);
  position: relative;
  z-index: 2;
}


.ContactModalWrapper{
  display: flex;
  overflow-x: visible;
  overflow-y: scroll;
  height: calc(100%);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 5;

  pointer-events: none;

  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  transition: all 300ms ease-in-out;

  padding: 25px 25px;
  padding-right: 20px;
  padding-bottom: 115px;

  width: 450px;
}
.ContactIntro{
  width: calc(100%);
  background: #101010;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 20px 50px rgba(0,0,0,.1);

  opacity: 0;
  transform: scale(0.9);
  pointer-events: all;

  animation-name: animateIn;
  animation-delay: 600ms;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.ContactMinimize{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 34px;
  border-radius: 5px;
  background: #c2c2c2;
  color: #3b3b3b;
  font-size: 12px;
  position: absolute;
  top: -44px;
  left: 5px;
  cursor: pointer;
  opacity: 0;
  transition: all 150ms ease-in-out;
}
.ContactIntroTitle{
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  opacity: .9;
  letter-spacing: -0.4px;
}
.ContactIntroText{
  color: #fff;
  margin-top: 5px;
  opacity: .8;

  letter-spacing: -0.2px;
}
.ContactModal{
  width: calc(100%);
  background: #1b1b1b;
  padding: 25px;
  padding-bottom: 20px;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: 0 20px 50px rgba(0,0,0,.1);
  pointer-events: all;

  opacity: 0;
  transform: scale(0.9);


  animation-name: animateIn;
  animation-delay: 750ms;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.ContactModalTitle{
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  opacity: .9;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
}
.ContactItem{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 10px;
  margin: 0 -10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;

  transition: all 150ms ease-in-out;
}
.ContactItem:hover{
  background: rgba(255,255,255,.1);
}
.ContactItem:last-child{
  border-bottom: 0;
}
.ContactItemIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #3b3b3b;
}
.ContactItemValue{
  flex: 1;
  margin-top: 1.5px;
  margin-left: 15px;
  color: #fff;
  opacity: .9;

  letter-spacing: -0.2px;
}

@keyframes animateIn {
  0%{
    opacity: 0;
    transform: scale(0.9);
  }
  60%{
    opacity: 1;
    transform: scale(1.05);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}
