.Portfolio{
  width: calc(100%);
  height: calc(100%);

  perspective: 1000px;

  overflow-y: scroll;
}
.PortfolioSection{
  min-height: 100vh;
  padding: 50px;
  padding-top: 200px;
  padding-bottom: 100px;
}

.PortfolioSectionTitle{
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  opacity: .9;
  letter-spacing: -0.5px;
}
.PortfolioSectionText{
  flex: 1;
  color: #fff;
  margin-top: 15px;
  opacity: .8;

  letter-spacing: -0.2px;
}
.PortfolioSectionItems{
  width: calc(100% + 10px);
  margin-top: 40px;
  margin-left: -5px;
}
.PortfolioItem{
  display: inline-block;
  width: calc(20% - 10px);
  min-width: 250px;
  height: 375px;
  border-radius: 10px;
  overflow: hidden;
  background: #2b2b2b;

  cursor: zoom-in;

  position: relative;

  margin: 0 5px;
  margin-bottom: 2px;
  transition: all 150ms ease-in-out;
}
.PortfolioItem:hover{
  background: #3b3b3b;
}
.PortfolioItem:hover .PortfolioOverlay{
  background: rgba(0,0,0,.1);
}
.PortfolioItem:hover .PortfolioOverlayExpand{
  transform: scale(1);
  opacity: 1;
}
.PortfolioItem:hover .PortfolioItemImage{
  transform: scale(1.1);
}
.PortfolioOverlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;

  background: rgba(0,0,0,.2);
  transition: all 150ms ease-in-out;

  display: flex;
  pointer-events: none;

  align-items: center;
  justify-content: center;
}
.PortfolioOverlayExpand{
  display: flex;
  pointer-events: none;
  transition: all 150ms ease-in-out;
  transform: scale(.9);

  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(255,255,255,.3);
  font-size: 18px;

  opacity: .7;
}
.PortfolioItemImage{
  transition: all 5000ms ease-in-out;
  width: calc(100%);
  height: calc(100%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.PortfolioCategories{
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;

  margin: 0 -50px;
  padding: 0 40px;
  padding-bottom: 10px;

  margin-top: 15px;
}
.PortfolioCategories
::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: rgba(255,255,255,.0);
}
.PortfolioCategories
::-webkit-scrollbar
{
	height: 8px;
	width: 8px;
	background-color: rgba(255,255,255,.0);
}
.PortfolioCategories
::-webkit-scrollbar-thumb
{
  border-radius: 2px;
	background-color: rgba(173,0,1,.3);
}
.CategoryItem{
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  background: rgba(255,255,255,.2);
  padding: 0 15px;
  margin-right: 5px;
  transition: all 150ms ease-in-out;
  cursor: pointer;
}
.CategoryItem.selected{
  background: #ad0001;
}
.CategoryItem.selected .CategoryItemText{
  margin-right: 15px;
  margin-left: 7px;
}
.CategoryItemText{
  white-space: nowrap;
  margin-right: 8px;
  transition: all 150ms ease-in-out;
}
.CategoryItemArrow{
  font-size: 9px;
}
