.Panel{
  width: calc(100%);
  height: calc(100%);

  perspective: 1000px;

  padding: 15px;
  padding-top: 160px;

  overflow-y: scroll;
}
.PaneldAddItem{
  display: inline-block;
  margin: 2.5px;
  margin-bottom: -2.5px;
  background-position: center;
  background-color: #7b7b7b;
  border:  2px dashed #fff;
  background-size: cover;
  border-radius: 5px;
  width: calc(33% - 9px);
  padding-bottom: calc(33% - 9px);

  position: relative;
}
.PaneldAddItem .Icon{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 18px;
}
.Panel .PhotoItem{
  width: calc(33% - 5px);
  padding-bottom: calc(33% - 5px);
}
.PanelTitle{
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0 10px;
  opacity: .75;
}
.PanelButton{
  display: flex;
  align-items: center;
  padding: 0 15px;

  margin: 5px 0;
  height: 38px;
  background: rgba(255,255,255,.1);
}
.PanelButton.Disabled{
  opacity: .5 !important;
}
.PanelButtonText{
  flex: 1;
  font-size: 13px;
  font-weight: bold;
}
.PanelButtonIcon{
  font-size: 12px;
}
.PanelNavigation{
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding: 0 20px;
  padding-bottom: 15px;
  padding-top: 100px;
  background: #101010;
  border-bottom: solid 1px #1b1b1b;
}
.PanelPortfolio{
  position: relative;
  z-index: 1;
}
.PanelLoginView{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100%);
}
.PanelLogin{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100%);
  padding: 25px;
  border-radius: 15px;
}
.PanelLoginItem{
  width: calc(100%);
  margin: 10px 0;
}
.PanelLoginItemPre{
  font-weight: bold;
  font-size: 12px;
  padding: 0 15px;
  margin-bottom: 5px;
}
.PanelLoginItemInput{
  width: calc(100%);
  height: 38px;
  border-radius: 19px;
  padding: 0 15px;
  background: #efefef;
}
.PanelLoginItemInput::placeholder{
  color: #c2c2c2;
}
.PanelLoginButton{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: calc(100%);
  height: 38px;
  border-radius: 19px;
  padding: 0 15px;
  color: #fff;
  background: #ad0001;
}