.Loaded{
  display: flex;
  width: calc(100%);
  height: calc(100%);
}
.Loaded.covered .HomeHeader{
  background: #101010;
}
.LoadedContent{
  display: flex;
  width: calc(100%);
  height: calc(100%);
  opacity: 1;
  clip-path: circle(100%);
  overflow: hidden;
  transform: scale(1);
  position: relative;
  z-index: 2;


  transition: all 300ms ease-in-out;
}
.LoaderTransitioningLoader{
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-in-out;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.HomeHeader{
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  pointer-events: auto;

  transition: all 300ms ease-in-out;

  padding-top: 30px;
  padding-left: 30px;
  padding-right: 45px;
  padding-bottom: 15px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -o-user-select: none;
  -moz-user-select: none;
}
.HomeHeader.hide{
  transform: translateY(-100px);
  opacity: 0;
}

.HomeHeaderLogoView{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.HomeHeaderLogoText{
  margin-left: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.5px;
}
.HomeHeaderLogoTextRed{
  transition: all 300ms ease-in-out;
  color: #ad0001;
}
.HomeHeaderLogo{
  width: 80px;
  height: 80px;

  transition: all 300ms ease-in-out;
}
.HomeHeaderLogo:hover{
  transform: scale(1.1) rotate(360deg);
}
.HomeNavigation{
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.HomeNavigationMenu{
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none;
  transition: all 300ms ease-in-out;
}
.HomeNavigationMenuBurger{
  display: none;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.2px;
  color: rgba(255,255,255,.8);
  background: rgba(255,255,255,.1);
  cursor: pointer;

  align-items: center;
  justify-content: center;
  pointer-events: all;

  height: 40px;
  line-height: 34px;
  width: 40px;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  transition: all ease-in-out 300ms;
}
.HomeNavigationItem{
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.2px;
  color: rgba(255,255,255,.7);
  cursor: pointer;

  position: relative;
  z-index: 5;

  height: 34px;
  line-height: 34px;
  padding: 0 15px;
  border-radius: 5px;
  position: relative;
  transition: all ease-in-out 300ms;
}
.HomeNavigationItemBall{
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  margin-right: 8px;
  box-shadow: 2px 2px 0px #ad0001;
}
.HomeNavigationItemSub{
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
  margin-left: 5px;
}
.HomeNavigationItem.selected{
  color: #fff;
  text-shadow: 2px 2px 0px #ad0001;
}
.HomeNavigationItem.selected .HomeNavigationItemSub{
  text-shadow: none;
}
.HomeNavigationItem.selected::after{

}
.HomeNavigationItem::after{

}
.HomeNavigationItem:hover{
  color:  #fff;
}
.HomeNavigationItem:hover::after{

}

.homeFooterStatic{
  display: block;
  pointer-events: none;
  margin-top: 50px;
  padding: 10px 40px 20px 40px;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 300ms;
}
.homeFooter{
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  pointer-events: none;
  padding: 40px 45px 40px 40px;
  pointer-events: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all ease-in-out 300ms;
}
.homeFooter.hidden{
  transform: translateY(200px);
}
.homeFooterAnimation{
  position: absolute;
  bottom: 40px;
  opacity: .7;
  right: 25px;
}
.homeFooterSocials{
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: all;
}
.homeFooterSocialItem{
  margin-right: 10px;
  width: 40px;
  height: 40px;
  font-size: 20px;

  opacity: .5;

  color: inherit;

  cursor: pointer;
  transition: all ease-in-out 300ms;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.homeFooterButton{
  margin-right: 10px;
  width: 40px;
  height: 40px;
  font-size: 20px;

  opacity: .5;

  cursor: pointer;
  transition: all ease-in-out 300ms;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.homeFooterSocialItem:hover, .homeFooterButton:hover{
  opacity: 1;
}
.homeFooterSocialItem.fb:hover{
  color: #4267B2;
}
.homeFooterSocialItem.ig:hover{
  color: #e1306c;
}
.homeFooterSocialItem.tt:hover{
  color: #69C9D0;
}
.homeFooterCopyright{
  flex: 1;
  opacity: .5;
  margin-left: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  position: relative;
}
.homeFooterCopyrightLine{
  flex: 1;
  pointer-events: none;
  /* margin-right: 20px;
  height: 1px;
  border-radius: 1px;
  background: #fff; */
}
.homeFooterCopyrightText{
  
}
.loadedPopupOverlay{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  pointer-events: none;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  opacity: 0;
  transition: all 300ms ease-in-out;

  background: rgba(0,0,0,0.85);
}
.loadedPopupOverlay.show{
  opacity: 1;
  pointer-events: all;
}
.loadedPopupOverlay.show{
  opacity: 1;
  transform: scale(1);
}
.loadedPopupImageView{
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  padding: 25px;
  width: calc(100%);
  height: calc(100%);
}
.loadedPopupImageViewClose{
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 0;
  right: 0;
  width: 70px;
  height: 50px;

  padding-bottom: 5px;
  padding-left: 10px;
  z-index: 20;
  border-radius: 0 0 0 50px;
  background: #3b3b3b;
}
.loadedPopupImage{
  position: relative;
  max-width: calc(100%);
  max-height: calc(100%);
  transform: scale(0.9);
  pointer-events: all;
  border-radius: 5px;

  animation-name: ImageIntro;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.HomeMobileOnly{
  display: none;
}
.HomeMobileOnlyCC{
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 20px;
  /* pointer-events: none; */

  width: calc(100%);
  justify-content: center;
  align-items: center;
}
.HomeDesktopOnly{
  display: flex;
}

.HomeMobileOnlyCopyright{
  flex: 1;
  pointer-events: none;
  opacity: .5;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.HomeMobileOnlyCopyrightText{
  pointer-events: none;
}

.HomeNavigationLightning{
  position: absolute;
  bottom: 0;
  left: -10vw;
  right: -10vw;
  width: 120vw;
  height: 120vw;
  pointer-events: none;
  z-index: 3;
}

@keyframes ImageIntro {
  0%{
    transform: scale(0.9);
  }
  50%{
    transform: scale(1.05);
  }
  100%{
    transform: scale(1);
  }
}
