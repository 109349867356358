.About{
  width: calc(100%);
  height: calc(100%);

  perspective: 1000px;

  padding-bottom: 30px;
}
.AboutWrapper{
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  align-items: flex-start;
}
.AboutWrapperImage{
  flex: 2;
  height: 100vh;
  overflow: hidden;
}
.AboutWrapperDetails{
  flex: 3;
  height: calc(100%);
  overflow-y: scroll;
}
.AboutSectionWrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.AboutSection{
  display: flex;
  flex-direction: row;
  padding: 30px 50px;
}
.AboutSectionOptions{
  display: flex;
  margin-left: 40px;
  margin-top: 20px;
  padding-top: 15px;
  border-top: solid 1px rgba(255,255,255,.2);
}
.AboutSectionOptionsButton{
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  border-radius: 5px;
  background: rgba(255,255,255,.2);
  color: #fff;
  font-weight: bold;
  white-space: nowrap;

  cursor: pointer;
  transition: all ease-in-out 150ms;
}
.AboutSectionOptionsButton:hover{
  background: rgba(255,255,255,.4);
}
.AboutSection:nth-child(1){
  /* padding-top: 250px; */
}

.AboutSectionTitle{
  width: 400px;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  opacity: .9;
  letter-spacing: -1px;
  margin-left: -5px;
}
.AboutSectionDetails{
  flex: 1;
}
.homeContentModalSignature.about{
  margin-top: 40px;
  color: #fff;
  padding-left: 40px;
  text-shadow: 2px 2px 0px #ad0001;
}
.AboutSectionText{
  flex: 1;
  color: #fff;
  margin-top: 15px;
  margin-left: 20px;
  padding-left: 20px;
  opacity: .8;

  border-left: solid 1px rgba(255,255,255,.2);

  letter-spacing: -0.2px;
}
.GoUpButton{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #ad0001;
  color: #fff;

  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 10;
  font-size: 12px;
  cursor: pointer;

  transition: all 300ms ease-in-out;
}
.GoUpButton:hover{
  transform: scale(1.01);
}
.AboutInfoSectionViewWrapper{
  width: calc(100%);
}
.AboutInfoSectionView{
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 25px 0px;
}
.AboutInfoSectionView.mid{
  height: auto;
}
.AboutInfoSectionView:last-child{
  margin-bottom: 50px;
}
.AboutInfoSectionImage{
  display: flex;
  flex: 1;
  height: calc(100%);
  flex-direction: row;

  align-items: center;
  justify-content: center;
}
.AboutInfoSectionImageImage{
  width: calc(100%);
  height: calc(100%);
  object-fit: cover;
}
.AboutInfoSectionImageImage.mask{
  /* -webkit-mask-image: url('/public/globals/ink_splatter.png');
  mask-image: url('/public/globals/ink_splatter.png'); */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}
.AboutInfoSectionImageImage.mask2{
  margin-top: -50px;
  transform: scale(1.1);
  -webkit-mask-image: url('/public/globals/ink_splatter_2.png');
  mask-image: url('/public/globals/ink_splatter_2.png');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}
.AboutInfoSectionDetails{
  flex: 1;
  padding: 50px 75px 50px 75px;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.AboutInfoSectionDetailsTitle{
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  opacity: .9;
  letter-spacing: -1px;
  margin-left: -5px;
}
.AboutInfoSectionDetailsContent{
  margin-top: 20px;
  color: #fff;
  margin-left: 20px;
  padding-left: 20px;
  opacity: .8;

  border-left: solid 1px rgba(255,255,255,.2);

  letter-spacing: -0.2px;
}
.AboutInfoSectionArtists{
  margin-top: 75px;

  display: flex;
  flex-direction: row;
  align-items: center;
}
.AboutInfoSectionArtistItem{
  height: 350px;
  flex: 1;
  margin-right: 5px;
  position: relative;

  background: #3b3b3b;
  border-radius: 5px;
  transition: all ease-in-out 150ms;
}
.AboutInfoSectionArtistItem:hover .AboutInfoSectionArtistItemName{
  color: #ad0001;
}
.AboutInfoSectionArtistItem:hover{
  background: #4b4b4b;
  text-shadow: 4px 4px 0px #101010;
}
.AboutInfoSectionArtistItem:hover .AboutInfoSectionArtistFront{
  transform: scale(1.02);
  opacity: 1;
}
.AboutInfoSectionArtistItemName{
  color: #fff;
  font-family: 'Cherish', cursive;
  font-size: 80px;
  letter-spacing: -0.2px;
  text-align: center;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}
.AboutInfoSectionArtistItemName.smaller{
  font-size: 65px;
}
.AboutInfoSectionArtistBack{
  height: calc(100%);
  width: calc(100%);
  background-size: cover;
  border-radius: 5px;
  background-position: bottom center;

}
.AboutInfoSectionArtistFrontWrapper{
  border-radius: 5px;
  overflow: hidden;
  width: calc(100%);
  height: calc(100%);
}
.AboutInfoSectionArtistFront{
  position:absolute;
  border-radius: 5px;
  width: calc(100%);
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: scale(1);
  transition: all 300ms ease-in-out;
}
