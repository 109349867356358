.Services{
  width: calc(100%);
  height: calc(100%);

  perspective: 1000px;

  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: scroll;

}
.Services.tattoo{
  padding: 75px 0 125px 0;
}
.ServiceItem{
  flex: 1;
  height: calc(100%);
  padding: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ServiceItem::after{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.7);
  transition: 150ms all ease-in-out;
  z-index: 5;
}
.ServiceItem:hover::after{
  background: rgba(0,0,0,.5);
}
.ServiceItem:hover .ServiceItemText{
  transform: translateY(-5px);
  opacity: 1;
}
.ServiceItem:nth-child(1){
  background-color: #151515;
}
.ServiceItem:nth-child(2){
  background-color: #181818;
}
.ServiceItem:nth-child(3){
  background-color: #212121;
}
.ServiceItemText{
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  opacity: .7;
  letter-spacing: -1px;
  font-weight: bold;
  position: relative;
  z-index: 10;
  transition: 150ms all ease-in-out;
}
.ServicesInfoSectionView{
  display: flex;
  flex-direction: row;
  height: 100vh;
  margin-top: 100px;
}
.ServicesInfoSectionView:nth-child(even){
  flex-direction: row-reverse;
}
.ServicesInfoSectionDetails{
  flex: 3;
  padding: 50px 75px 50px 75px;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.ServicesInfoSectionDetailsTitle{
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  opacity: .9;
  letter-spacing: -1px;
  margin-left: -5px;
}
.ServicesInfoSectionDetailsContent{
  margin-top: 20px;
  color: #fff;
  margin-left: 20px;
  padding-left: 20px;
  opacity: .8;

  border-left: solid 1px rgba(255,255,255,.2);

  letter-spacing: -0.2px;
}
.ServicesInfoSectionViewImageContainer{
  height: calc(100%);
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ServicesInfoSectionViewImage{
  height: 400px;
  width: 300px;
  border-radius: 5px;
  background-color: #2b2b2b;
  background-size: cover;
  background-position: center;
}
