.Profile{
  width: calc(100%);
  height: calc(100%);

  perspective: 1000px;

  overflow-y: scroll;
}
.ProfileCarousel{
  display: block;
  width: calc(100%);
  background: #1b1b1b;
  height: 250px;
  position: relative;
  z-index: 1;
}
.ProfileCarousel::before{
  position: absolute;
  content: "";
  bottom: 0;
  z-index: 2;
  height: calc(100%);
  width: calc(100%);
  background: linear-gradient(to bottom,  rgba(27, 27, 27, 0),  rgba(27, 27, 27, 1));
}
.ProfileCarouselImage{
  width: calc(100%);
  height: calc(100%);
  background-position: center;
  background-size: cover;
}
.ProfileHeaderWrapper{
  background: #1b1b1b;
  position: relative;
}
.ProfileHeaderPlan{
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 15px;
  top: -10px;
  left: 0;
  right: 0;
}
.ProfileHeaderPlanCard{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-radius: 2.5px;
  background: #0482ee;
  color: #fff;
  height: 20px;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
}
.ProfileHeader{
  margin: 0 10px;
  z-index: 5;
  padding: 15px 18px;
  position: relative;

  transform: translateY(-50px);

  background: #2b2b2b;
  border-radius: 10px;
  background: linear-gradient(
        to right,
        rgb(239, 123, 12) 0%,
        #ad0001 30%,
        #ad0001 70%,
        rgb(239, 123, 12) 100%
    );
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
}
.ProfileHeaderName{
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.2x;
}
@keyframes textShine {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}
.ProfileCheck{
  font-size: 13px;
  color: #fff;
}
.ProfileHeaderSub{
  text-align: center;
  font-size: 11px;
  opacity: .85;
}
.ProfileNavigation{
  display: flex;
  align-items: center;
  margin-top: -50px;
  padding: 10px;
}
.ProfileNavigationItem{
  flex: 1;
  height: 38px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: #c2c2c2;
}
.ProfileNavigationItem:hover{
  color: #efefef;
}
.ProfileNavigationItem.Selected{
  font-weight: bold;
  background: rgba(255,255,255,.05);
  color: #fff;
}
.ProfileContent{
  flex-wrap: wrap;
  min-height: calc(100vh - 64px);
  padding-bottom: 125px;
}
.AlbumNavigation{
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
.AlbumNavigationBack{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  background: #2b2b2b;
  margin-right: 10px;
}
.AlbumNavigationTitle{
  font-weight: bold;
  margin-left: 10px;
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  color: #fff;
}
.PhotoItem{
  display: inline-block;
  margin: 2.5px;
  margin-bottom: -2.5px;
  background-position: center;
  background-color: #2b2b2b;
  background-size: cover;

  border-radius: 5px;
  width: calc(50% - 10px);
  padding-bottom: calc(50% - 10px);
}
.AlbumItem{
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-bottom: solid 1px rgba(255,255,255,.05);
}
.AlbumItemImage{
  width: 100px;
  height: 80px;
  border-radius: 5px;
  background-color: #2b2b2b;
  background-position: center;
  background-size: cover;
}
.AlbumItemImage.Smaller{
  width: 80px;
  height: 60px;
}
.AlbumItemDetails{
  flex: 1;
  margin-left: 15px;
}
.AlbumItemDetailsName{
  font-weight: bold;
  color: #fff;
}
.AlbumItemDetailsPhotos{
  font-size: 12px;
  color: #c2c2c2;
}
.ProfileLogo{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 5px;
  right: 15px;
  z-index: 10;
  width: 50px;
  height: 50px;
  background: #1b1b1b;
  border-radius: 30px;
}
.ProfileLogoImage{
  width: 45px;
  height: 45px;
}