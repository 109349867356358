.Shop{
  width: calc(100%);
  height: calc(100%);

  perspective: 1000px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ShopAnimation{
  width: 100px;
  height: 100px;
}
.ShopTextView{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ShopText{
  font-weight: bold;
  letter-spacing: -5px;
  color: rgba(255,255,255,.03);
  font-size: 80px;
  text-align: center;
  white-space: wrap;
  line-height: 80px;
}
