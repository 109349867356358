body{
  overflow-x:hidden;
  zoom:0;

	background:  #000;
	color: #fff;
  font-family: 'Lato', sans-serif;
	-webkit-font-smoothing: subpixel-antialiased;
	font-size:14px;
	position:relative;
}

* {
	outline: none;
	-webkit-box-sizing: border-box;   /* Safari 3-4, also probably droppable */
	-moz-box-sizing: border-box;   /* Firefox 1, probably can drop this */
    box-sizing:	border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0) !important;

	-webkit-appearance: none;
  line-height: 22px;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: rgba(255,255,255,.0);
}

::-webkit-scrollbar
{
	height: 8px;
	width: 8px;
	background-color: rgba(255,255,255,.0);
}

::-webkit-scrollbar-thumb
{
  border-radius: 2px;
	background-color: rgba(173,0,1,.3);
} */

::-webkit-selection {background: #b3d4fd;}
::-moz-selection {background: #b3d4fd; color: #000;}
::selection {background: #b3d4fd;}

#root, body, html{
  width: calc(100%);
  height: calc(100%);

  overflow: hidden;
}

.Loading{
  display: flex;
  flex-direction: column;
  width: calc(100%);
  height: calc(100%);

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  align-items: center;
  justify-content: center;
  transition: all ease-in-out 300ms;
}
.Loading.hide .LoadingLogo{
  animation-name: LoadingHide;
  animation-duration: 1000ms;
}
.LoadingLogo{
  display: block;
  width: 150px;
  height: 150px;

  filter: blur(0);
  transform: rotate(-180deg);

  opacity: 0;
  animation-name: LoadingAnimated;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  position: relative;
  z-index: 15;
}
.LoadingAnimation{
  width: calc(100%);
  height: calc(100%);

  z-index: 10;
}
.LoadingAnimationView{
  display: flex;
  width: calc(100%);
  height: calc(100%);
  align-items: center;
  justify-content: center;
  opacity: .4;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.App{
  display: flex;
  width: calc(100%);
  height: calc(100%);

  background: #101010;

  opacity: 0;
  transform: scale(0.95);

  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.App.show{
  animation-name: AppFadeIn;
}

@keyframes AppFadeIn {
  0%{
    opacity: 0;
    transform: scale(0.95);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes LoadingAnimated {
  0%{
    opacity: 0;
    transform: rotate(-180deg);
  }
  10%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  90%{
    opacity: 1;
    transform: rotate(0deg);
  }
  100%{
    opacity: 1;
    transform: rotate(0deg);
  }
}

@keyframes LoadingHide {
  0%{
    opacity: 1;
    transform: scale(1);
  }
  60%{
    filter: blur(0);
    opacity: 1;
    transform: scale(0.9);
  }
  90%{
    opacity: 0;
  }
  100%{
    opacity: 0;
    transform: scale(10);
    filter: blur(20px);
  }
}
