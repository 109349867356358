.Home{
  display: flex;
  width: calc(100%);
  height: calc(100%);

  perspective: 1000px;
}
.Home.focused .homeContentModal{
  opacity: 0;
}
.Home.focused .homeVideoOverlay{
  opacity: .3;
}
.Home.focused .homeCardHover{
  opacity: 0;
  transform: translateY(360px);
}
.Loaded.transitioning .LoadedContent{
  opacity: 0;
  clip-path: circle(0%);
  transform: scale(0.95);
}
.Loaded.hovered .HomeHeaderLogoTextRed{
  color: rgba(255, 255, 255, .7);
}
.Loaded.hovered .HomeHeaderLogo{
  filter: brightness(0) invert(1);
}
.Home.hovered .homeVideoOverlay{
  background: rgba(174, 0, 1);
  opacity: .5;
}
.Home.hovered .homeCardHover{
  transform: translateY(360px);
}

.homeHoverTextWrapper{
  display: flex;
  pointer-events: none;

  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;

  padding: 20px;

  transition: all 500ms ease-in-out;
}
.homeHoverTextWrapper.show{
  opacity: 1;
}
.homeHoverText{
  font-size: 200px;
  line-height: 200px;

  font-weight: 900;
  color: #fff;
  opacity: .1;

  text-align: center;
  letter-spacing: -5px;

  pointer-events: none;
}

.homeVideo{
  display: flex;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
}
.homeVideoVideo{
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}
.homeVideoOverlay{
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 5;
  background: #000;
  opacity: .7;
  transition: all 300ms ease-in-out;
}
.homeContent{
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;

  padding: 60px;
  
  align-items: center;
  justify-content: center;
}
.homeContentModalPlay{
  position: absolute;
  top: -20vh;
  right: -25vh;
  z-index: 20;
  pointer-events: none;
}
.homeContentModalPlayButtonBig{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vh;
  height: 100vh;
  border-radius: 50%;
  background: rgba(255,255,255,.01);

  animation-name: PlayButtonBigAnimated;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.homeContentModalPlayButtonMedium{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vh;
  height: 40vh;
  border-radius: 50%;
  background: rgba(255,255,255,.01);

  animation-delay: 500ms;
  animation-name: PlayButtonBigAnimated;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.homeContentModalPlayButtonSmall{
  width: 15vh;
  height: 15vh;
  border-radius: 50%;
  background: rgba(255,255,255,.01);

  animation-delay: 500ms;
  animation-name: PlayButtonBigAnimated;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.homeContentModalPlayButton{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  pointer-events: all;
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -20px;
  z-index: 5;
  width: 40px;
  height: 40px;
  font-size: 12px;
  border-radius: 20px;
  background: rgba(255,255,255,.2);

  cursor: pointer;

  transition: all ease-in-out 300ms;
}
.homeContentModalPlayButton:hover{
  background: #ad0001;
  transform: scale(1.2);
}
.homeContentModal{
  transition: all ease-in-out 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homeContentModalTitle{
  color: #ad0001;
  text-shadow: 0 0 50px rgba(0,0,0,.5);
  font-weight: bold;
  font-size: 45px;
  line-height: 50px;
  letter-spacing: -1px;
  opacity: .9;
  text-align: center;
  margin-left: -5px;
  font-family: 'Cinzel', serif;

  background: linear-gradient(
        to right,
        rgb(239, 123, 12) 0%,
        #ad0001 30%,
        #ad0001 70%,
        rgb(239, 123, 12) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
}
@keyframes textShine {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}
.homeContentModalText{
  color: #fff;
  margin-top: 5px;
  max-width: 550px;
  opacity: .7;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.5px;
}
.homeContentModalSignature{
  margin-top: 60px;
  color: #fff;
  font-family: 'Mrs Saint Delafield', cursive;
  font-size: 40px;
}
.homeContentModalOptions{
  display: inline-block;

  margin-top: 20px;
  padding-top: 15px;
  border-top: solid 1px rgba(255,255,255,.3);
}
.homeContentModalOptionsButton{
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  border-radius: 5px;
  background: rgba(255,255,255,.2);
  color: #fff;
  font-weight: bold;

  cursor: pointer;
  transition: all ease-in-out 150ms;
}
.homeContentModalOptionsButton:hover{
  background: rgba(255,255,255,.4);
}

.homeCardView{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;

  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -o-user-select: none;
  -moz-user-select: none;
}
.homeCardHover{
  pointer-events: all;
  transform: translateY(200px);
  transition: all ease-in-out 150ms;
}
.homeCardHover:hover{
  transform: translateY(50px);
}
.homeCardHover:hover .homeCardItem:nth-child(1){
  transform: rotate(-30deg) translateX(-50px) translateY(50px);
}
.homeCardHover:hover .homeCardItem:nth-child(2){
  transform: rotate(-15deg) translateX(-50px);
}
.homeCardHover:hover .homeCardItem:nth-child(3){
  transform: scale(1.1);
}
.homeCardHover:hover .homeCardItem:nth-child(4){
  transform: rotate(15deg) translateX(50px);
}
.homeCardHover:hover .homeCardItem:nth-child(5){
  transform: rotate(30deg) translateX(50px) translateY(50px);
}
.homeCardHover:hover .homeCardHeader{
  opacity: 0;
  transform: translateY(200px);
}
.homeCardHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  transition: all ease-in-out 300ms;
}
.homeCardItemDate{
  position: absolute;
  top: 25px;
  left: 30px;
  font-weight: bold;
  color: #fff;
  letter-spacing: -0.2px;
}
.homeCardHeaderTitle{
  font-weight: bold;
  color: #fff;
  opacity: .6;
}
.homeCardHeaderLine{
  margin-top: 10px;
  height: 50px;
  width: 2px;
  border-radius: 2px;

  background: #fff;
  opacity: .6;
}
.homeCardWrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.homeCardItem{
  width: 250px;
  height: 350px;
  border-radius: 20px;

  padding: 10px;
  background: #fff;
  /* background: #101010; */
  position: relative;
  z-index: 3;
  transition: margin-bottom ease-in-out 100ms, transform ease-in-out 100ms;
  box-shadow: 0 20px 100px rgba(0,0,0,.5);
}

.homeCardItem:hover{
  z-index: 20 !important;
  margin-bottom: 10px !important;
}

.homeCardItem:nth-child(1){
  margin-right: -20px;
  margin-bottom: -55px;
  transform: rotate(-20deg);
  transform-origin: top right;
}
.homeCardItem:nth-child(2){
  margin-bottom: -10px;
  transform: rotate(-6deg);
  transform-origin: top right;
}
.homeCardItem:nth-child(3){
  z-index: 5;
  margin: 0 -120px;

  transform-origin: bottom center;
}
.homeCardItem:nth-child(4){
  z-index: 4;
  margin-bottom: -10px;
  transform: rotate(6deg);
  transform-origin: top left;
}
.homeCardItem:nth-child(5){
  margin-left: -20px;
  margin-bottom: -55px;
  transform: rotate(20deg);
  transform-origin: top left;
}
.homeCardItemImage{
  height: calc(100%);
  background-color: rgba(0,0,0,0.03);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: 10px;
  overflow: hidden;

  box-shadow: inset 0 10px 30px rgba(0,0,0,.5);
}

.homeCardOptions{
  display: flex;

  position: absolute;
  bottom: 75px;
  left: 0;
  right: 0;
  z-index: 30;

  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.homeCardOptionsButton{
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  border-radius: 5px;
  background: #ad0001;
  color: #fff;
  font-weight: bold;

  box-shadow: 0 10px 300px #ad0001;

  cursor: pointer;
}

@keyframes PlayButtonBigAnimated {
  0%{
    transform: scale(0.9);
    background: rgba(255,255,255,.01);
  }
  50%{
    transform: scale(1.2);
    background: rgba(255,255,255,.05);
  }
  100%{
    transform: scale(0.9);
    background: rgba(255,255,255,.01);
  }
}
