@media only screen and (min-width: 800px) {

  .HomeNavigationItem{
    pointer-events: all;
  }


}


@media only screen and (max-width: 1100px) {

  .HomeNavigation{
    flex: 0;
  }
  .HomeHeaderLogoView{
    flex: 1;
  }
  .HomeNavigationMenuBurger{
    display: flex;
    position: relative;
    z-index: 51;
  }
  .HomeNavigationMenu:not(.show){
    clip-path: circle(0%);
  }
  .HomeHeaderLogoView{
    z-index: 51;
  }
  .HomeNavigationMenu.show{
    backdrop-filter: blur(25px);
    pointer-events: all;
  }
  .HomeNavigationMenu{
    flex-direction: column;
    position: fixed;
    background: rgba(0,0,0,.3);
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    width: calc(100%);
    height: calc(100%);
    z-index: 50;
    pointer-events: none;
    clip-path: circle(100%);

    justify-content: center;
    backdrop-filter: blur(0px);
  }
  .HomeNavigationMenu.show
  .HomeNavigationItem{
    animation-name: menuIntro;
  }

  .HomeNavigationMenu.show
  .HomeNavigationItem:nth-child(1){
    animation-delay: 50ms;
  }
  .HomeNavigationMenu.show
  .HomeNavigationItem:nth-child(2){
    animation-delay: 100ms;
  }
  .HomeNavigationMenu.show
  .HomeNavigationItem:nth-child(3){
    animation-delay: 150ms;
  }
  .HomeNavigationMenu.show
  .HomeNavigationItem:nth-child(4){
    animation-delay: 200ms;
  }
  .HomeNavigationMenu.show
  .HomeNavigationItem:nth-child(5){
    animation-delay: 250ms;
  }
  .LoadedContent.menuOpened{
    clip-path: circle(50%);
    transform: scale(0.9) translateY(0);
  }
  .HomeNavigationItem{
    margin: 12px 0;
    font-size: 25px;
    height: 50px;
    line-height: 50px;
    letter-spacing: -0.7px;

    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .AboutWrapper{
    display: block !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
  }
  .AboutWrapperImage{
    flex: 0 !important;
    height: 300px !important;
  }
  .AboutWrapperDetails{
    flex: 0 !important;
    height: auto !important;
    overflow-y: hidden !important;
  }
  .AboutInfoSectionView, .ServicesInfoSectionView{
    height: auto !important;
  }
  .AboutInfoSectionView:first-child{
    margin-top: 0 !important;
  }
  .AboutInfoSectionView:last-child{
    margin-bottom: 0 !important;
  }
  .AboutInfoSectionDetails{
    padding: 25px 30px !important;
  }

  @keyframes menuIntro {
    0%{
      transform: scale(0.9) translateY(0);
    }
    50%{
      transform: scale(1.1) translateY(0);
    }
    100%{
      transform: scale(1) translateY(0);
    }
  }

}

@media only screen and (max-width: 800px) {

  .HomeMobileOnly{
    display: flex;
  }
  .HomeDesktopOnly{
    display: none;
  }
  .homeContentModalSignature{
    margin-top: 20px;
  }

  .HomeHeader{
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
  }
  .HomeHeaderLogoText{
    margin-left: 0;
    font-size: 16px;
  }
  .HomeHeaderLogo{
    width: 60px;
    height: 60px;
  }
  .homeCardView{
    display: none;
  }
  .homeContent, .PortfolioSection{
    padding: 5px;
    padding-top: 150px;
  }
  .PortfolioSectionTitle, .PortfolioSectionText{
    padding: 0 20px;
  }
  .homeContentModal{
    width: calc(100%);
  }
  .homeContentModalTitle{
    font-size: 25px;
    line-height: 25px;
  }
  .homeContentModalText{
    font-size: 13px;
  }
  .homeContentModalSignature{
    font-size: 30px;
  }
  .homeFooter{
    flex-direction: column;
    padding: 15px 20px 20px 20px;
  }
  .homeFooterCopyright{
    flex: 0;
    margin-top: 10px;
    margin-left: 0;
  }
  .Services.tattoo{
    padding-top: 75px;
  }
  .AboutSection, .ServicesInfoSectionView, .ServicesInfoSectionDetails{
    flex-direction: column !important;
    padding: 25px 15px;
  }
  .ServicesInfoSectionView{
    margin-top: 0;
  }
  .AboutInfoSectionArtists{
    flex-direction: column;
  }
  .AboutInfoSectionArtistItem{
    flex: none;
    width: calc(100%);
  }
  .AboutInfoSectionArtistItem:first-child{
    margin-bottom: 50px;
  }
  .AboutSectionTitle{
    width: calc(100%);
    margin-left: 0;
  }
  .homeFooterAnimation{
    display: none;
  }
  .homeContentModalPlay{
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -20vh;
    right: 50%;
    margin-right: -50vh;
    z-index: 20;
    width: 100vh;
    height: 100vh;
    pointer-events: none;
  }
  .AboutSectionTitle, .ServicesInfoSectionDetailsTitle{
    font-size: 30px;
    line-height: 40px;
  }
  .PortfolioCategories{
    margin: 0 -5px;
    padding: 0 25px;
    padding-bottom: 20px;
    margin-top: 15px;
    margin-bottom: -25px;
  }
  .PortfolioItem{
    width: calc(50% - 10px);
    height: auto;
    min-width: 0;
    padding-bottom: calc(50% - 30px);
  }
  .PortfolioItemImage{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .PortfolioSectionItems{
    width: calc(100%);
    margin-left: 0;
  }
  .PortfolioSection{
    padding-top: 150px;
    padding-bottom: 20px;
  }
  .Services{
    flex-direction: column;
  }
  .ServiceItem{
    width: calc(100%);
    height: auto;
    padding: 30px;
  }
  .ServiceItemText{
    font-size: 35px;
    line-height: 35px;
  }
  .ServiceItem:nth-child(1){
    padding-top: 100px;
  }
  .ServiceItem:nth-child(2){
  }
  .ServiceItem:nth-child(3){

  }
  .AboutInfoSectionView{
    flex-direction: column;
  }
  .AboutSectionOptions{
    display: flex;
    justify-content: center;
    margin-left: 0;
    padding: 0 20px;
    padding-bottom: 15px;
    border-bottom: solid 1px rgba(255,255,255,.2);
    border-top: 0;
  }

  .ContactModalWrapper{
    width: calc(100%);
    padding-bottom: 50px;

    padding-top: calc(25% + 100px);
    display: block;
    pointer-events: all;
  }
  .ContactModalWrapper.hide{
    transform: translateX(calc(100vw - 50px));
  }
  .ContactModalWrapper.hide .ContactMinimize{
    left: -25px;
    width: 50px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ContactMinimize{
    opacity: 1;
    pointer-events: all;
  }

}
